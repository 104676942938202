import { useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleQuestion,
    faCoins,
    faEarthAsia,
    faEllipsisVertical,
    faGear,
    faKeyboard,
    faSignOut,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import Menu from '~/components/Popper/Menu';

import axios from 'axios';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames/bind';

import { get, post } from '~/utils/httpRequest';
import styles from './Test.module.scss';

const cx = classNames.bind(styles);

function Test() {
    const domain = 'http://api.site.local';

    const [ariaExpanded, setAriaExpanded] = useState('false');

    // PHP
    // echo openssl_encrypt('mot hai ba', 'aes-256-cbc', '1A6YVV6hLH4lKacZifq2unM83KMJ975w', 0, 'ceeA2WVPiEYf0mhV');
    // echo openssl_decrypt('aLnVSE5xmF4y0hRzr1w/XA==', 'aes-256-cbc', '1A6YVV6hLH4lKacZifq2unM83KMJ975w', 0, hex2bin('ceeA2WVPiEYf0mhV'));

    const password = CryptoJS.enc.Utf8.parse('1A6YVV6hLH4lKacZifq2unM83KMJ975w');

    axios.defaults.withCredentials = true;

    const handleLogin = () => {
        axios
            .post(domain + '/user/login', {
                email: 'dkhoa096@gmail.com',
                password: '12345678',
            })
            .then(function (response) {
                console.log('response', response.headers);
            });
    };
    const handleGetUser = async () => {
        const res = await get('/user', {});
        console.log('handleGetUser', res);
    };
    const handleLogout = () => {
        axios.get(domain + '/user/logout', {}).then(function (response) {});
    };
    const handleChange = (file) => {
        console.log('file 1', file);
    };
    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };
    const handleEncodeAES = () => {
        let iv = CryptoJS.enc.Utf8.parse(makeid(16));
        let encrypted = CryptoJS.AES.encrypt('Hello Encrypted World!', password, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
            hasher: CryptoJS.algo.SHA256,
        }).toString();
        console.log('encrypted', iv.toString() + encrypted);
    };
    const handleDecodeAES = () => {
        let transitmessage = '636565413257565069455966306d6856aLnVSE5xmF4y0hRzr1w/XA==';
        let iv = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
        transitmessage = transitmessage.substring(32);
        let decrypted = CryptoJS.AES.decrypt(transitmessage, password, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
            hasher: CryptoJS.algo.SHA256,
        }).toString(CryptoJS.enc.Utf8);
        console.log('decrypted', decrypted);
    };
    // Handle logic
    const handleMenuChange = (menuItem) => {
        switch (menuItem.type) {
            case 'language':
                // Handle change language
                break;
            default:
        }
    };
    const MENU_ITEMS = [
        {
            icon: <FontAwesomeIcon icon={faEarthAsia} />,
            title: 'English',
            children: {
                title: 'Language',
                data: [
                    {
                        type: 'language',
                        code: 'en',
                        title: 'English',
                    },
                    {
                        type: 'language',
                        code: 'vi',
                        title: 'Tiếng Việt',
                    },
                ],
            },
        },
        {
            icon: <FontAwesomeIcon icon={faCircleQuestion} />,
            title: 'Feedback and help',
            to: '/feedback',
        },
        {
            icon: <FontAwesomeIcon icon={faKeyboard} />,
            title: 'Keyboard shortcuts',
        },
    ];

    return (
        <Fragment>
            <h2>Hello new day!!!</h2>
            <Button variant="primary" size="sm" onClick={handleLogin}>
                Login
            </Button>
            <br></br>
            <br></br>
            <Button variant="primary" size="sm" onClick={handleGetUser}>
                Get User
            </Button>
            <br></br>
            <br></br>
            <Button variant="primary" size="sm" onClick={handleLogout}>
                Logout
            </Button>
            <br></br>
            <br></br>
            <Button variant="primary" size="sm" onClick={handleEncodeAES}>
                Encode AES
            </Button>
            <br></br>
            <br></br>
            <Button variant="primary" size="sm" onClick={handleDecodeAES}>
                Decode AES
            </Button>
            <br></br>
            <br></br>
            <Menu items={MENU_ITEMS} onChange={handleMenuChange}>
                <Button variant="primary" size="sm">
                    Tippy
                </Button>
            </Menu>
        </Fragment>
    );
}

export default Test;
