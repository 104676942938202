import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import classNames from 'classnames/bind';

import { getTimeAgo } from '~/services/dateTimeService';
import images from '~/assets/images';
import styles from './SmallNewItem.module.scss';

const cx = classNames.bind(styles);

function SmallNewItem({ id = '', title = '', image = '', createdDate = '' }) {
    const titleLength = 20;

    const navigate = useNavigate();

    const handleGoNew = (id) => {
        navigate('/new/' + id);
    };

    return (
        <div className={cx('wrapper-img', 'mb-2')}>
            <div className={cx('d-flex', 'align-items-center')} onClick={() => handleGoNew(id)}>
                <div className={cx('flex-shrink-0')}>
                    <div className={cx('flex-shrink-0')}>
                        <Image className={cx('img')} src={image ? image : images.noImage} />
                    </div>
                </div>
                {title.length <= titleLength && (
                    <div className={('flex-grow-1', 'ms-2')}>
                        {title}
                        <br></br>
                        {createdDate && (
                            <small className={cx('text-secondary')}>Last updated {getTimeAgo(createdDate)}</small>
                        )}
                    </div>
                )}
                {title.length > titleLength && (
                    <div className={('flex-grow-1', 'ms-2')}>
                        {title.substring(0, titleLength) + '...'}
                        <br></br>
                        {createdDate && (
                            <small className={cx('text-secondary')}>Last updated {getTimeAgo(createdDate)}</small>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SmallNewItem;
