import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faTv } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames/bind';

import { isAdminMode } from '~/services/authService';
import styles from './LeftBar.module.scss';
import config from '~/config';

const cx = classNames.bind(styles);

function LeftBar() {
    const navigate = useNavigate();

    const pathName = window.location.pathname;

    const handleGoHome = () => {
        navigate('/');
    };
    const handleGoAboutMe = () => {
        navigate('/about-me');
    };
    const handleGoNew = () => {
        navigate('/new');
    };
    const handleGoCv = () => {
        navigate('/cv');
    };
    const handleGoAdminNew = () => {
        navigate('/admin/new');
    };
    const handleGoShare = () => {
        navigate('/share');
    };

    return (
        <Fragment>
            {!isAdminMode() ? (
                <div className={cx('leftbar', 'p-1', 'text-center', 'wrapper')}>
                    <div
                        className={cx('item', 'mb-1', 'pt-4', 'fs-6', pathName == config.routes.home ? 'active' : '')}
                        onClick={handleGoHome}
                    >
                        <FontAwesomeIcon icon={faTv} className={cx('icon')} />
                        <br />
                        Home
                    </div>
                    <div
                        className={cx(
                            'item',
                            'mb-1',
                            'pt-4',
                            'fs-6',
                            pathName == config.routes.aboutMe ? 'active' : '',
                        )}
                        onClick={handleGoAboutMe}
                    >
                        <FontAwesomeIcon icon={faUser} className={cx('icon')} />
                        <br />
                        About Me
                    </div>
                    <div
                        className={cx('item', 'mb-1', 'pt-4', 'fs-6', pathName == config.routes.new ? 'active' : '')}
                        onClick={handleGoNew}
                    >
                        <FontAwesomeIcon icon={faNewspaper} className={cx('icon')} />
                        <br />
                        News
                    </div>
                    <div
                        className={cx('item', 'mb-1', 'pt-4', 'fs-6', pathName == config.routes.cv ? 'active' : '')}
                        onClick={handleGoCv}
                    >
                        <FontAwesomeIcon icon={faAddressCard} className={cx('icon')} />
                        <br />
                        CV
                    </div>
                    <div
                        className={cx('item', 'mb-1', 'pt-4', 'fs-6', pathName == config.routes.share ? 'active' : '')}
                        onClick={handleGoShare}
                    >
                        <FontAwesomeIcon icon={faShareFromSquare} className={cx('icon')} />
                        <br />
                        Share
                    </div>
                </div>
            ) : (
                <div className={cx('leftbar', 'p-1', 'text-center', 'wrapper')}>
                    <div
                        className={cx(
                            'item',
                            'mb-1',
                            'pt-4',
                            'fs-6',
                            cx(pathName == config.routes.adminNew ? 'active' : ''),
                        )}
                        onClick={handleGoAdminNew}
                    >
                        <FontAwesomeIcon icon={faNewspaper} className={cx('icon')} />
                        <br />
                        News
                    </div>
                </div>
            )}
        </Fragment>
    );
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { auth: stage.auth.user },
    };
}
export default connect(mapStageToProp)(LeftBar);
