import { useState, Fragment, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';

import { put } from '~/utils/httpRequest';
import styles from './HeaderResetPassword.module.scss';
import apiStatus from '~/config/apiStatus';

const cx = classNames.bind(styles);

function HeaderResetPassword({ validated, setValidated, showModal, setShowModal }) {
    const hiddenSubmitRef = useRef();

    const [showMsg, setShowMsg] = useState(false);
    const [isLoginLoading, setLoginLoading] = useState(false);
    const [resetPasswordLogin, setResetPasswordLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [googleCaptcha, setGoogleCaptcha] = useState(false);

    const googleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY;

    const handleSubmit = () => {
        hiddenSubmitRef.current.click();
    };
    const handleSubmitForm = async (event) => {
        const form = event.currentTarget;
        let validatedTerm = validated;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            validatedTerm = true;
            setValidated(validatedTerm);
        } else {
            // Check repeat password
            if (!googleCaptcha) {
                setShowMsg('Please verify your captcha.');
                return;
            }
            // Check repeat password
            if (passwordLogin != resetPasswordLogin) {
                setShowMsg('Password and Repeat Password are not same.');
                return;
            }

            validatedTerm = false;
            setValidated(validatedTerm);
        }

        if (!validatedTerm) {
            setLoginLoading(true);

            const res = await put(
                '/user/reset-password',
                {
                    password: passwordLogin,
                    password_confirmation: resetPasswordLogin,
                    token: googleCaptcha,
                },
                {},
                { headers: { accept: '*/*', 'Content-Type': 'application/json' }, aes: true },
            );

            if (res.status == apiStatus.success) {
                handleCloseModal();
            } else {
                setShowMsg(res.msg);
            }

            setLoginLoading(false);
        }
    };
    const handleCloseModal = () => {
        setResetPasswordLogin('');
        setPasswordLogin('');
        setShowModal(false);
        setShowMsg(false);
        setGoogleCaptcha(false);
        setLoginLoading(false);
    };
    const handleCaptchaChange = (value) => {
        setGoogleCaptcha(value);
    };

    return (
        <Fragment>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmitForm} id="signin">
                        <Form.Group className="mb-3" controlId="validationCustom02">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={passwordLogin}
                                pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{6,}"
                                onChange={(e) => {
                                    setPasswordLogin(e.target.value);
                                }}
                                required
                                autoComplete="off"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                This field cannot be empty and your password must be 8-20 characters long, contain
                                letters and numbers, and must not contain spaces, special characters, or emoji..
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="validationCustom01">
                            <Form.Label>Repeat Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={resetPasswordLogin}
                                pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{6,}"
                                onChange={(e) => {
                                    setResetPasswordLogin(e.target.value);
                                }}
                                required
                                autoComplete="off"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                This field cannot be empty and your reset password must be 8-20 characters long, contain
                                letters and numbers, and must not contain spaces, special characters, or emoji..
                            </Form.Control.Feedback>
                        </Form.Group>
                        {showMsg && (
                            <Alert
                                className={cx('mt-3')}
                                variant="danger"
                                onClose={() => setShowMsg(false)}
                                dismissible
                            >
                                {showMsg}
                            </Alert>
                        )}
                        <Button ref={hiddenSubmitRef} variant="primary" type="submit" hidden></Button>
                    </Form>
                    {!isLoginLoading && <ReCAPTCHA sitekey={googleSiteKey} onChange={handleCaptchaChange} />}
                    <div className={cx('pt-3')}>
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faExpeditedssl} /> SSL
                        </span>
                        {' | '}
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faShieldAlt} /> AES
                        </span>
                        {' | '}
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faGoogle} /> Google Captcha
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={!isLoginLoading ? handleSubmit : null} disabled={isLoginLoading}>
                        {isLoginLoading ? 'Loading…' : 'Reset'}
                    </Button>
                    {!isLoginLoading && (
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default HeaderResetPassword;
