import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'react-bootstrap/Image';
import classNames from 'classnames/bind';

import { getTimeAgo } from '~/services/dateTimeService';
import { get } from '~/utils/httpRequest';
import images from '~/assets/images';
import styles from './ShowNew.module.scss';
import apiStatus from '~/config/apiStatus';
import Loading from '~/components/Loading';

const cx = classNames.bind(styles);

function ShowNew() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [newData, setNewData] = useState({});
    const [image, setImage] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNew(id);
    }, []);

    const getNew = async (id) => {
        let responseNews = await get('/new/' + id, {});
        if (responseNews.status == apiStatus.success) {
            let responseData = responseNews.data;
            if (responseData.image_url) {
                setImage(JSON.parse(responseData.image_url));
            }
            setNewData(responseData);
            setLoading(false);
        } else {
            navigate('/new');
        }
    };
    const handleGoNew = () => {
        navigate(-1);
    };

    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>
                <FontAwesomeIcon className={cx('icon')} icon={faArrowLeftLong} onClick={handleGoNew} />{' '}
                {newData.new_title}
            </h4>
            <div className={cx('wrapper-content')}>
                {loading ? (
                    <div className={cx('p-3')}>
                        <Loading />
                    </div>
                ) : (
                    <div className={cx('p-3')}>
                        <div className={cx('text-center', 'mb-3')}>
                            {image &&
                                image.map((item, index) => {
                                    return (
                                        <Image
                                            key={index}
                                            className={cx('img-sub', 'pe-1')}
                                            src={!item['web_path'] ? images.noImage : item['web_path']}
                                            fluid
                                            rounded
                                        />
                                    );
                                })}
                        </div>
                        <div
                            className={cx('ck-content')}
                            dangerouslySetInnerHTML={{ __html: newData.new_content }}
                        ></div>
                        {newData.created_date && (
                            <div>
                                <small className={cx('text-secondary')}>
                                    Last updated {getTimeAgo(newData.created_date)} by {newData.author_short_name}
                                </small>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default ShowNew;
