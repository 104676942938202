import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { faUserGraduate, faBuilding, faTreeCity } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { isLogin } from '~/services/authService';
import 'react-vertical-timeline-component/style.min.css';

import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames/bind';
import HeaderLogin from '~/layouts/components/HeaderLogin';

import styles from './WorkingHistory.module.scss';

const cx = classNames.bind(styles);

function WorkingHistory() {
    const [loginValidated, setLoginValidated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [show, setShow] = useState(false);
    const [showSi, setShowSi] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseSi = () => setShowSi(false);
    const handleShowSi = () => setShowSi(true);

    const handleGoLuongVanCan = () => {
        window.open('https://thptluongvancan.hcm.edu.vn/homegd8', '_blank', 'noopener,noreferrer');
    };
    const handleGoLyTuTrong = () => {
        window.open('https://tuyensinh.lttc.edu.vn', '_blank', 'noopener,noreferrer');
    };
    const handleGoItvina = () => {
        window.open('https://itvina.com', '_blank', 'noopener,noreferrer');
    };
    const handleGoHanbiro = () => {
        window.open('https://hanbiro.vn', '_blank', 'noopener,noreferrer');
    };
    const handleGoMbiz = () => {
        window.open('https://brandmetaverse.ch', '_blank', 'noopener,noreferrer');
    };
    const handleLoginShow = () => {
        setLoginValidated(false);
        setShowLogin(true);
    };

    return (
        <div className={cx('wrapper')}>
            {isLogin() ? (
                <VerticalTimeline className={cx('vertical-timeline-custom-line')}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2023 - 2024"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faTreeCity} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">FREELANCER OUTSOURCING</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p className="fw-normal">
                            Software engineer ( Laravel, CodeIgniter, JS, ReactJS, ElasticSearch, MYSQL )<br></br>
                            <br></br>
                            <Button variant="outline-secondary" onClick={handleShow}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2022 - 2023"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faBuilding} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">M-BIZ Company ( SWITZERLAND )</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p className="fw-normal">
                            Software engineer ( Laravel, CodeIgniter, JS, ReactJS, BlockChain, TelegramBot, MYSQL,
                            MongoDB, GITBash )<br></br>
                            <br></br>
                            <Button variant="outline-secondary" onClick={handleGoMbiz}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2019 - 2022"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faBuilding} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">HANBIRO Company ( KOREA )</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p className="fw-normal">
                            PHP Programmer ( Laravel, CodeIgniter, JS, AngularJS, ReactJS, ElasticSearch, MYSQL,
                            MongoDB, GITBash )<br></br>
                            <br></br>
                            <Button variant="outline-secondary" onClick={handleGoHanbiro}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2019 - 2019"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faBuilding} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">SI Company ( KOREA )</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p className="fw-normal">
                            PHP Programmer ( Laravel, CodeIgniter, JS, ReactJS )<br></br>
                            <br></br>
                            <Button variant="outline-secondary" onClick={handleShowSi}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2017 - 2019"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faBuilding} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">ITVINA Company ( VN )</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p className="fw-normal">
                            PHP Programmer ( Laravel, CodeIgniter, JS, ReactJS )<br></br>
                            <br></br>
                            <Button variant="outline-secondary" onClick={handleGoItvina}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2014 - 2017"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faUserGraduate} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">Ly Tu Trong Technical College</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p>
                            <Button variant="outline-secondary" onClick={handleGoLyTuTrong}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--content"
                        date="2011 - 2013"
                        iconStyle={{ background: 'var(--active-color)', color: '#fff' }}
                        icon={<FontAwesomeIcon className={cx('icon')} icon={faUserGraduate} />}
                        contentStyle={{
                            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                        }}
                        contentArrowStyle={{ borderRight: '7px solid  var(--active-color)' }}
                    >
                        <h4 className="vertical-timeline-element-title fs-4">Luong Van Can High School</h4>
                        <h5 className="vertical-timeline-element-subtitle fs-5">Ho Chi Minh, Viet Nam.</h5>
                        <p>
                            <Button variant="outline-secondary" onClick={handleGoLuongVanCan}>
                                Go to
                            </Button>
                        </p>
                    </VerticalTimelineElement>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Notice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>You're on Jocelyn's page.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showSi} onHide={handleCloseSi}>
                        <Modal.Header closeButton>
                            <Modal.Title>Notice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>This company was closed, the website's not active now.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseSi}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </VerticalTimeline>
            ) : (
                <div className={cx('text-center')}>
                    <div className={cx('need-login', 'p-3')}>
                        <div className={cx('mb-2')}>You need to login to see this content.</div>
                        <Button variant="outline-primary" onClick={handleLoginShow}>
                            Login now
                        </Button>
                    </div>
                </div>
            )}
            <HeaderLogin
                loginValidated={loginValidated}
                setLoginValidated={setLoginValidated}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
        </div>
    );
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { auth: stage.auth.user },
    };
}
export default connect(mapStageToProp)(WorkingHistory);
