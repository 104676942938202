import * as httpRequest from '~/utils/httpRequest';

export const getAll = async () => {
    const res = await httpRequest
        .get('check/get-all', {
            params: {},
        })
        .catch(function (error) {
            console.log('error', error.config);
        });
    return res && res.data ? res.data : false;
};
