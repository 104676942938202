import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import classNames from 'classnames/bind';
import Table from 'react-bootstrap/Table';

import { get, del } from '~/utils/httpRequest';
import styles from './AdminNew.module.scss';
import apiStatus from '~/config/apiStatus';

const cx = classNames.bind(styles);

function AdminNew() {
    const navigate = useNavigate();

    const [news, setNews] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [availablePages, setAvailablePages] = useState([]);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        getAllNews(page);
    }, []);

    const handleGoAddNew = () => {
        navigate('/admin/new/create');
    };
    const handleGoEditNew = (id) => {
        if (id) {
            navigate('/admin/new/' + id + '/edit');
        }
    };
    const getAllNews = async (pageParam) => {
        let responseNews = await get('/new', { page: pageParam, limit: limit });
        let lastPage = Math.ceil(responseNews.count / limit);
        let currentPage = responseNews.current_page;
        let availablePages = [];

        if (currentPage - 2 > 0) {
            availablePages.push(currentPage - 2);
        }
        if (currentPage - 1 > 0) {
            availablePages.push(currentPage - 1);
        }
        availablePages.push(currentPage);
        if (currentPage + 1 <= lastPage) {
            availablePages.push(currentPage + 1);
        }
        if (currentPage + 2 <= lastPage) {
            availablePages.push(currentPage + 2);
        }

        setAvailablePages(availablePages);
        setLastPage(lastPage);
        setPage(currentPage);
        setNews(responseNews.data);
    };
    const handleGoToPage = (pageParam) => {
        if (pageParam != page) {
            getAllNews(pageParam);
        }
    };
    const handleDelete = async (id, title) => {
        if (id) {
            if (window.confirm('Are you sure you want to delete this row "' + title + '" ?')) {
                let responseNews = await del('/new/' + id, {});
                if (responseNews.status == apiStatus.success) {
                    getAllNews(page);
                    setModalMsg('Deleted Success');
                    handleShow();
                } else {
                    setModalMsg('Deleted Fail');
                    handleShow();
                }
            }
        }
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>News</h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <div className={cx('mb-2', 'toolbar')}>
                    <Button variant="primary" onClick={handleGoAddNew}>
                        <FontAwesomeIcon className={cx('icon', 'text-white')} icon={faCirclePlus} /> Add New
                    </Button>
                </div>
                <Table responsive className={cx('table-ct')}>
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}>#</th>
                            <th style={{ width: '20%' }}>Title</th>
                            <th style={{ width: '35%' }}>Content</th>
                            <th style={{ width: '10%', textAlign: 'center' }}>Author</th>
                            <th style={{ width: '5%', textAlign: 'center' }}>Type</th>
                            <th style={{ width: '5%', textAlign: 'center' }}>Status</th>
                            <th style={{ width: '12%', textAlign: 'center' }}>Created date</th>
                            <th style={{ width: '10%', textAlign: 'center' }}>Functions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {news.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {item.new_title.length > 20
                                            ? item.new_title.substring(0, 20) + '...'
                                            : item.new_title}
                                    </td>
                                    <td>
                                        {item.new_content_plain_text.length > 50
                                            ? item.new_content_plain_text.substring(0, 50) + '...'
                                            : item.new_content_plain_text}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>{item.author_full_name}</td>
                                    <td style={{ textAlign: 'center' }}>{item.new_type}</td>
                                    <td style={{ textAlign: 'center' }}>Active</td>
                                    <td style={{ textAlign: 'center' }}>{item.created_date}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => handleGoEditNew(item.new_id)}
                                        >
                                            Edit
                                        </Button>{' '}
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            onClick={() => handleDelete(item.new_id, item.new_title)}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <div className={cx('d-flex', 'justify-content-center', 'cv', 'p-2', 'mb-1')}>
                <Pagination>
                    <Pagination.First onClick={() => handleGoToPage(1)} />
                    {/* <Pagination.Prev /> */}

                    {availablePages &&
                        availablePages.map((item, index) => {
                            return (
                                <Pagination.Item
                                    key={index}
                                    active={item == page ? 'active' : ''}
                                    onClick={() => handleGoToPage(item)}
                                >
                                    {item}
                                </Pagination.Item>
                            );
                        })}

                    {/* <Pagination.Next /> */}
                    <Pagination.Last onClick={() => handleGoToPage(lastPage)} />
                </Pagination>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default AdminNew;
