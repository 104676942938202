import { Fragment } from 'react';
import classNames from 'classnames/bind';

import styles from './CurriculumVitae.module.scss';
import Cv from '~/components/Cv';

const cx = classNames.bind(styles);

function CurriculumVitae() {
    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Curriculum Vitae</h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <Cv />
            </div>
        </Fragment>
    );
}

export default CurriculumVitae;
