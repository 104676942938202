import axios from 'axios';
import CryptoJS from 'crypto-js';

import { isAdmin, resetAdminToGuest, isLogin, logout } from '~/services/authService';
import apiStatus from '~/config/apiStatus';

const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};
const handleEncodeAES = (text = '') => {
    let iv = CryptoJS.enc.Utf8.parse(makeid(16));
    let encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_PASSWORD), {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
        hasher: CryptoJS.algo.SHA256,
    }).toString();
    return iv.toString() + encrypted;
};
const handleDecodeAES = (text = '') => {
    let iv = CryptoJS.enc.Hex.parse(text.substr(0, 32));
    text = text.substring(32);
    let decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_PASSWORD), {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
        hasher: CryptoJS.algo.SHA256,
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
};
const handleEncodeObjAES = (obj = {}) => {
    let newObj = {};
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let item = obj[key];
        newObj[handleEncodeAES(key)] = handleEncodeAES(item);
    }
    return newObj;
};
/**
 * @response: true => call API again, false => stop call API
 */
async function validateResponseStatus(props) {
    let response = props.requestResponse;

    // Check refresh token
    if (isLogin() && response && response.status == apiStatus.unauthentication) {
        let responseApi = await httpRequest.post('/user/reresh-token', {});
        let dataApi = responseApi.data;
        if (responseApi && dataApi.status && dataApi.status == apiStatus.success) {
            return true;
        } else {
            logout({ isDeleteCookie: false });
            return false;
        }
    }

    // Check permission
    if (isLogin() && response && response.status == apiStatus.permission_deny) {
        if (isAdmin()) {
            resetAdminToGuest();
        }
        return false;
    }

    return false;
}

const httpRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

export const get = async (path, options = {}, settings = {}, config = {}) => {
    try {
        let strParameters = '';
        for (let key in options) {
            if (strParameters != '') {
                strParameters += '&';
            }
            strParameters += key + '=' + encodeURIComponent(options[key]);
        }

        const response = await httpRequest.get(path + '?' + strParameters, config);
        if (!settings.no_validate) {
            const status = await validateResponseStatus({ requestResponse: response.data });
            if (status == true) {
                return get(path, options, { no_validate: true }, config);
            }
        }
        return response.data;
    } catch (err) {
        return {
            status: apiStatus.error,
            msg: err.message,
        };
    }
};
export const post = async (path, options = {}, settings = {}, config = {}) => {
    try {
        let parameters = Object.assign({}, options);
        if (config.aes) {
            parameters = handleEncodeObjAES(parameters);
        }

        const response = await httpRequest.post(path, parameters, config);
        if (!settings.no_validate) {
            const status = await validateResponseStatus({ requestResponse: response.data });
            if (status == true) {
                return post(path, options, { no_validate: true }, config);
            }
        }
        return response.data;
    } catch (err) {
        return {
            status: apiStatus.error,
            msg: err.message,
        };
    }
};
export const del = async (path, options = {}, settings = {}, config = {}) => {
    try {
        let strParameters = '';
        for (let key in options) {
            if (strParameters != '') {
                strParameters += '&';
            }
            strParameters += key + '=' + encodeURIComponent(options[key]);
        }

        const response = await httpRequest.delete(path + '?' + strParameters, config);
        if (!settings.no_validate) {
            const status = await validateResponseStatus({ requestResponse: response.data });
            if (status == true) {
                return del(path, options, { no_validate: true }, config);
            }
        }
        return response.data;
    } catch (err) {
        return {
            status: apiStatus.error,
            msg: err.message,
        };
    }
};
export const put = async (path, options = {}, settings = {}, config = {}) => {
    try {
        let parameters = Object.assign({}, options);
        if (config.aes) {
            parameters = handleEncodeObjAES(parameters);
        }

        const response = await httpRequest.put(path, parameters, config);
        if (!settings.no_validate) {
            const status = await validateResponseStatus({ requestResponse: response.data });
            if (status == true) {
                return put(path, options, { no_validate: true }, config);
            }
        }
        return response.data;
    } catch (err) {
        return {
            status: apiStatus.error,
            msg: err.message,
        };
    }
};

export default httpRequest;
