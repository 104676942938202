const images = {
    logo: require('~/assets/images/logo.svg').default,
    noImage: require('~/assets/images/no-image.png'),
    php: require('~/assets/images/php.png'),
    reactjs: require('~/assets/images/reactjs.png'),
    javascript: require('~/assets/images/javascript.png'),
    jquery: require('~/assets/images/jquery.png'),
    mysql: require('~/assets/images/mysql.png'),
    laravel: require('~/assets/images/laravel.png'),
    favicon: require('~/assets/images/logo_icon.png'),
    aboutMeAvt: require('~/assets/images/about_me_avt.jpg'),
    bannerOne: require('~/assets/images/banner2.png'),
    commingSoon: require('~/assets/images/comming_soon.jpg'),
    notFound404: require('~/assets/images/404_not_found.png'),
};

export default images;
