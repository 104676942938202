import { authType } from '../types';

const authAction = {};

Object.keys(authType).forEach((key) => {
    const type = authType[key];

    authAction[key] = (data) => {
        return {
            type: type,
            payload: data,
        };
    };
});

export default authAction;
