import { Fragment } from 'react';
import { Image } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './Share.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function Share() {
    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>PHP</h4>
            <div className={cx('wrapper-content', 'p-2', 'text-center', 'wrapper-content-sub')}>
                <Image className={cx('img')} src={images.commingSoon} />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Javascript</h4>
            <div className={cx('wrapper-content', 'p-2', 'text-center', 'wrapper-content-sub')}>
                <Image className={cx('img')} src={images.commingSoon} />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Jquery</h4>
            <div className={cx('wrapper-content', 'p-2', 'text-center', 'wrapper-content-sub')}>
                <Image className={cx('img')} src={images.commingSoon} />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>ReactJS</h4>
            <div className={cx('wrapper-content', 'p-2', 'text-center', 'wrapper-content-sub')}>
                <Image className={cx('img')} src={images.commingSoon} />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>MySQL</h4>
            <div className={cx('wrapper-content', 'p-2', 'text-center', 'wrapper-content-sub')}>
                <Image className={cx('img')} src={images.commingSoon} />
            </div>
        </Fragment>
    );
}

export default Share;
