import { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames/bind';
import Poppy from '../Poppy';
import { isMobile } from 'react-device-detect';

import styles from './LeftNotification.module.scss';

const cx = classNames.bind(styles);

function LeftNotification() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <span className={cx('move-to-top')}>
                <FontAwesomeIcon className={cx('icon', 'bell')} icon={faBell} onClick={handleShow} />
                {!isMobile && <Poppy />}
            </span>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={cx('ck-content')}
                        dangerouslySetInnerHTML={{
                            __html: "<h2 style='text-align: center;'>General conditions Policy</h2><p>Hi everybody,&nbsp;<br>Firstly, I wish all the best to you!<br><br>Today, I would like to share an important thing,&nbsp;<br>That is “General Conditions Policy” of ITSharing,&nbsp;<br>When you use ITSharing, please follow our policy below:</p><ul><li>Do not use ITSharing for evil purposes and affect individuals or organizations</li><li>Do not use ITSharing for personal or organizational profit</li><li>ITSharing was created with free purpose, so when you use ITSharing, you have to agree it</li><li>Kind and polite when use ITSharing</li><li>If we find any actions that not meet our standards, we'll notice to you and we can block your account forever</li><li>Sensitive topics are strictly prohibited</li><li>When we receive any reporting about your account activity, we'll block it momentarily for considering, if we don't see any strange, we'll open your account again, opposite, we'll notice to you and block your account</li><li>When you comment or post any new on ITSharing, your news will be filtered by us, if content is good, we open it on ITSharing, opposite, we'll notice to you that block reason</li><li>When you create your account on ITSharing, we'll college your account information on our server and keep it in private, but you need to verify your account in 1 month, if you don't verify your account in 1 month, we'll delete it forever and you cannot recover your data account again</li><li>In the future, General Conditions Policy can be changed and we'll notice to you, when &nbsp;you use ITSharing, you have accept this as well</li></ul><p>All information above is General Conditions Policy of us,&nbsp;<br>When you use ITSharing, please follow content of policy above,&nbsp;<br>Thank you and have a good day!<br><br>Best regards,<br>Jocelyn.<br>&nbsp;</p>",
                        }}
                    ></div>
                    <span className={cx('text-secondary', 'fs-6', 'fw-light')}>Created at 02/04/2024 22:00:00</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default LeftNotification;
