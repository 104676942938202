import { Link } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames/bind';
import Button from 'react-bootstrap/Button';

import styles from './NotFound404.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function NotFound404() {
    return (
        <div className={cx('not-found')}>
            <img src={images.notFound404} alt="not-found" className={cx('pb-1')} />
            <br></br>
            <Link className to="/">
                <Button variant="outline-secondary">Go Home</Button>
            </Link>
        </div>
    );
}

export default NotFound404;
