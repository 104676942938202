import { Fragment, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { convert } from 'html-to-text';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';

import { post } from '~/utils/httpRequest';
import styles from './AdminNewCreate.module.scss';
import apiStatus from '~/config/apiStatus';
import ImageBase64 from '~/components/ImageBase64';

const cx = classNames.bind(styles);

function AdminNewCreate() {
    const navigate = useNavigate();
    const submitLoginRef = useRef();

    const [validated, setValidated] = useState(false);
    const [isLoginLoading, setLoginLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [type, setType] = useState('normal');
    const [contentPlainText, setContentPlainText] = useState('');
    const [fileSubmit, setFileSubmit] = useState({});

    const handleSave = () => {
        submitLoginRef.current.click();
    };
    const handleFormSubmit = async (event) => {
        const form = event.currentTarget;
        let validatedTerm = validated;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            validatedTerm = true;
            setValidated(validatedTerm);
        } else {
            if (!content) {
                setShowMsg('Please input content.');
                return;
            }

            validatedTerm = false;
            setValidated(validatedTerm);
        }

        if (!validatedTerm) {
            setLoginLoading(true);

            const res = await post('/new', {
                new_title: title,
                new_content: content,
                new_content_plain_text: contentPlainText,
                new_type: 'important',
                new_type: type,
                files: fileSubmit,
            });

            if (res.status == apiStatus.success) {
                setTitle('');
                setContent('');
                setShowMsg('');
                setFileSubmit({});
            } else {
                setShowMsg(res.msg);
            }

            setLoginLoading(false);
        }
    };
    const handleChangeFile = (index, file) => {
        let fileSubmitClone = Object.assign({}, fileSubmit);
        if (file) {
            fileSubmitClone[index] = {
                base64: file.base64,
                name: file.name,
            };
            setFileSubmit(fileSubmitClone);
        } else {
            delete fileSubmitClone[index];
            setFileSubmit(fileSubmitClone);
        }
    };
    const handleGoNew = () => {
        navigate(-1);
    };

    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>
                <FontAwesomeIcon className={cx('icon')} icon={faArrowLeftLong} onClick={handleGoNew} /> Add New
            </h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} required onChange={(e) => setTitle(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">This field cannot be empty.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom03">
                        <Form.Label>Type</Form.Label>
                        <Form.Select onChange={(e) => setType(e.target.value)}>
                            <option value="">Normal</option>
                            <option value="IMPORTANT">Important</option>
                            <option value="JOCELYN_SKILL">Jocelyn Skill</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Content</Form.Label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                scayt_autoStartup: false,
                                removePlugins: [],
                                toolbar: [
                                    'bold',
                                    'italic',
                                    '|',
                                    'Heading',
                                    'bulletedList',
                                    'numberedList',
                                    'blockQuote',
                                    'link',
                                    '|',
                                    'undo',
                                    'redo',
                                ],
                            }}
                            data={content}
                            onReady={(editor) => {}}
                            onChange={(event) => {}}
                            onBlur={(event, editor) => {
                                let html = editor.getData();
                                setContent(html);
                                setContentPlainText(
                                    convert(html, {
                                        wordwrap: false,
                                    })
                                        .replace(/\n/g, ' ')
                                        .replace(/\s\s+/g, ' '),
                                );
                            }}
                            onFocus={(event, editor) => {}}
                        />
                        <Button ref={submitLoginRef} variant="primary" type="submit" hidden></Button>
                    </Form.Group>
                    {[0, 1, 2, 3, 4, 5].map((item, index) => {
                        return (
                            <Form.Group key={index} className="mb-3" controlId="validationCustom02">
                                <Form.Label>Image {item + 1}</Form.Label>
                                <ImageBase64
                                    file={fileSubmit[index]}
                                    onChange={(file) => {
                                        handleChangeFile(item, file);
                                    }}
                                />
                            </Form.Group>
                        );
                    })}
                    {showMsg && (
                        <Alert className={cx('mt-3')} variant="danger" onClose={() => setShowMsg(false)} dismissible>
                            {showMsg}
                        </Alert>
                    )}
                    <Form.Group className={cx('d-flex', 'flex-row-reverse')}>
                        <Button
                            variant="primary"
                            onClick={!isLoginLoading ? handleSave : null}
                            disabled={isLoginLoading}
                        >
                            {isLoginLoading ? 'Loading…' : 'Save'}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Fragment>
    );
}

export default AdminNewCreate;
