import { useState } from 'react';
import classNames from 'classnames/bind';
import Carousel from 'react-bootstrap/Carousel';

import styles from './Banner.module.scss';
import SmallNew from '../SmallNew';

const cx = classNames.bind(styles);

function Banner() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div className={cx('wrapper', 'bd-highlight')}>
            <div className={cx('bd-highlight', 'important-new-one')}>
                <SmallNew title="Important news" apiType="important" seeMoreUrl="/new?type=important" />
            </div>
            <div className={cx('flex-grow-1', 'bd-highlight', 'banner')}>
                <Carousel activeIndex={index} interval={5000} onSelect={handleSelect}>
                    <Carousel.Item>
                        <div className={cx('wrapper-img')}>
                            <img
                                className={cx('img-item')}
                                src={require('~/assets/images/banner1.png')}
                                alt="First slide"
                            ></img>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={cx('wrapper-img')}>
                            <img
                                className={cx('img-item')}
                                src={require('~/assets/images/banner2.png')}
                                alt="First slide"
                            ></img>{' '}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={cx('wrapper-img')}>
                            <img
                                className={cx('img-item')}
                                src={require('~/assets/images/banner3.png')}
                                alt="First slide"
                            ></img>{' '}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className={cx('wrapper-img')}>
                            <img
                                className={cx('img-item')}
                                src={require('~/assets/images/banner4.png')}
                                alt="First slide"
                            ></img>{' '}
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className={cx('bd-highlight', 'important-new-two')}>
                <SmallNew title="Important news" apiType="important" seeMoreUrl="/new?type=important" />
            </div>
            <div className={cx('bd-highlight')}>
                <SmallNew title="Newest news" />
            </div>
        </div>
    );
}

export default Banner;
