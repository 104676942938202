import { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';

import { get, post } from '~/utils/httpRequest';
import styles from './HeaderSignup.module.scss';
import apiStatus from '~/config/apiStatus';

const cx = classNames.bind(styles);

function HeaderSignup({ showSignUp, setShowSignUp, signupValidated, setSignupValidated }) {
    const submitSignupRef = useRef();

    const [showMsg, setShowMsg] = useState(false);
    const [isSignupLoading, setSignupLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const [shortname, setShortname] = useState('');
    const [email, setEmail] = useState('');
    const [passwordSignup, setPasswordSignup] = useState('');
    const [repeatPasswordSignup, setRepeatPasswordSignup] = useState('');
    const [googleCaptcha, setGoogleCaptcha] = useState(false);

    const googleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY;

    const handleSignup = () => {
        submitSignupRef.current.click();
    };
    const handleSignupSubmit = async (event) => {
        const form = event.currentTarget;
        let signupValidatedTerm = signupValidated;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            signupValidatedTerm = true;
            setSignupValidated(signupValidatedTerm);
        } else {
            // Check repeat password
            if (passwordSignup != repeatPasswordSignup) {
                setShowMsg('Password and Repeat Password are not same.');
                return;
            }
            // Check repeat password
            if (!googleCaptcha) {
                setShowMsg('Please verify your captcha.');
                return;
            }

            signupValidatedTerm = false;
            setSignupValidated(signupValidatedTerm);
        }

        if (!signupValidatedTerm) {
            setSignupLoading(true);

            const res = await post(
                '/user/register',
                {
                    full_name: fullname,
                    short_name: shortname,
                    email: email,
                    password: passwordSignup,
                    password_confirmation: repeatPasswordSignup,
                    token: googleCaptcha,
                },
                {},
                { aes: true },
            );

            if (res.status == apiStatus.success) {
                handleSignUpClose();
            } else {
                setGoogleCaptcha(false);
                setShowMsg(res.msg);
            }

            setSignupLoading(false);
        }
    };
    const handleSignUpClose = () => {
        setFullname('');
        setShortname('');
        setEmail('');
        setPasswordSignup('');
        setRepeatPasswordSignup('');
        setShowMsg(false);
        setShowSignUp(false);
        setGoogleCaptcha(false);
        setSignupLoading(false);
    };
    const handleCaptchaChange = (value) => {
        setGoogleCaptcha(value);
    };

    return (
        <Modal show={showSignUp} onHide={handleSignUpClose} className={cx('modal-lg')}>
            <Modal.Header closeButton>
                <Modal.Title>Signup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={signupValidated} onSubmit={handleSignupSubmit} id="signup">
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Full name</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            onChange={(e) => setFullname(e.target.value)}
                            value={fullname}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">This field cannot be empty.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                        <Form.Label>Short Name ( Nick Name )</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            onChange={(e) => setShortname(e.target.value)}
                            value={shortname}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">This field cannot be empty.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom03">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            This field cannot be empty or format is different with email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom04">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{6,}"
                            onChange={(e) => setPasswordSignup(e.target.value)}
                            value={passwordSignup}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            This field cannot be empty and your password must be 8-20 characters long, contain letters
                            and numbers, and must not contain spaces, special characters, or emoji..
                        </Form.Control.Feedback>
                        <Form.Text id="passwordHelpBlock" muted>
                            Your password must be 8-20 characters long, contain letters and numbers, and must not
                            contain spaces, special characters, or emoji.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom04">
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{6,}"
                            onChange={(e) => setRepeatPasswordSignup(e.target.value)}
                            value={repeatPasswordSignup}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            This field cannot be empty and your password must be 8-20 characters long, contain letters
                            and numbers, and must not contain spaces, special characters, or emoji..
                        </Form.Control.Feedback>
                        <Form.Text id="passwordHelpBlock" muted>
                            Your password must be 8-20 characters long, contain letters and numbers, and must not
                            contain spaces, special characters, or emoji.
                        </Form.Text>
                    </Form.Group>
                    {showMsg && (
                        <Alert className={cx('mt-3')} variant="danger" onClose={() => setShowMsg(false)} dismissible>
                            {showMsg}
                        </Alert>
                    )}
                    <Button ref={submitSignupRef} variant="primary" type="submit" hidden></Button>
                </Form>
                {!isSignupLoading && <ReCAPTCHA sitekey={googleSiteKey} onChange={handleCaptchaChange} />}
                <div className={cx('pt-3')}>
                    <span className={cx('text-success')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faExpeditedssl} /> SSL
                    </span>
                    {' | '}
                    <span className={cx('text-success')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faShieldAlt} /> AES
                    </span>
                    {' | '}
                    <span className={cx('text-success')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faGoogle} /> Google Captcha
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="submit"
                    variant="outline-primary"
                    onClick={!isSignupLoading ? handleSignup : null}
                    disabled={isSignupLoading}
                >
                    {isSignupLoading ? 'Loading…' : 'Signup'}
                </Button>
                {!isSignupLoading && (
                    <Button variant="outline-secondary" onClick={handleSignUpClose}>
                        Close
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default HeaderSignup;
