import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getTimeAgo } from '~/services/dateTimeService';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Image from '~/components/Image';
import styles from './AccountItem.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function AccountItem({ data }) {
    const titleLength = 20;

    let imageUrlTerm = false;
    if (data.image_url) {
        imageUrlTerm = JSON.parse(data.image_url);
    }

    return (
        <Link to={`/new/${data.new_id}`} className={cx('wrapper')}>
            <Image
                className={cx('avatar')}
                src={imageUrlTerm ? imageUrlTerm[0]['web_path'] : images.noImage}
                alt={data.author_full_name}
            />
            <div className={cx('info')}>
                <h5 className={cx('m-0')}>
                    <span>
                        {data.new_title.length <= titleLength && <Fragment>{data.new_title}</Fragment>}
                        {data.new_title.length > titleLength && (
                            <Fragment>{data.new_title.substring(0, titleLength) + '...'}</Fragment>
                        )}
                    </span>
                </h5>
                <span className={cx('username')}>
                    Last updated {getTimeAgo(data.created_date)} by {data.author_short_name}
                </span>
            </div>
        </Link>
    );
}

AccountItem.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AccountItem;
