import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper, faAddressCard, faUser, faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { faEarthAsia } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import Menu from '~/components/Popper/Menu';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import classNames from 'classnames/bind';
import Search from '../Search';

import { isAdmin, logout, setModeAdminReverse, isAdminMode, isLogin, getAuth } from '~/services/authService';
import styles from './Header.module.scss';
import config from '~/config';
import images from '~/assets/images';
import HeaderLogin from '../HeaderLogin';
import HeaderSignup from '../HeaderSignup';
import HeaderResetPassword from '../HeaderResetPassword';

const cx = classNames.bind(styles);

function Header() {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [searchKeyword, setSearchKeyword] = useState('');

    const [loginValidated, setLoginValidated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [signupValidated, setSignupValidated] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    const [resetPasswordValidated, setResetPasswordValidated] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const auth = getAuth();
    const expand = 'xl';
    const pathName = window.location.pathname;
    const isAd = isAdmin();

    useEffect(() => {
        let searchKeywordTerm = searchParams.get('q');
        if (searchKeywordTerm) {
            setSearchKeyword(searchKeywordTerm);
        } else {
            setSearchKeyword('');
        }
    }, [searchParams]);

    const handleGoHome = () => {
        navigate('/');
    };
    const handleGoAboutMe = () => {
        navigate('/about-me');
    };
    const handleGoNew = () => {
        navigate('/new');
    };
    const handleGoAdminNew = () => {
        navigate('/admin/new');
    };
    const handleLoginShow = () => {
        setLoginValidated(false);
        setShowLogin(true);
    };
    const handleSignUpShow = () => {
        setSignupValidated(false);
        setShowSignUp(true);
    };
    const handleResetPasswordShow = () => {
        setResetPasswordValidated(false);
        setShowResetPasswordModal(true);
    };
    const handleLogout = () => {
        logout({ isDeleteCookie: true });
    };
    const handleEnterNew = (e) => {
        if (e.key === 'Enter') {
            navigate({
                pathname: '/new',
                search: createSearchParams({
                    q: searchKeyword,
                    page: 1,
                }).toString(),
            });
            e.preventDefault();
            e.stopPropagation();
        }
    };
    const handleGoModeAdmin = () => {
        setModeAdminReverse();
    };
    const MENU_ITEMS = [
        {
            icon: <FontAwesomeIcon icon={faAddressCard} />,
            title: 'Curriculum Vitae',
            to: '/cv',
        },
        {
            icon: <FontAwesomeIcon icon={faShareFromSquare} />,
            title: 'Share',
            to: '/share',
        },
        {
            icon: <FontAwesomeIcon icon={faEarthAsia} />,
            title: 'English',
            children: {
                title: 'Language',
                data: [
                    {
                        type: 'language',
                        code: 'en',
                        title: 'English',
                    },
                    {
                        type: 'language',
                        code: 'vi',
                        title: 'Tiếng Việt',
                    },
                ],
            },
        },
    ];
    const MENU_AUTH_ITEMS = [
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            title: 'Profile',
            to: '/profile',
        },
        {
            icon: <FontAwesomeIcon icon={faKey} />,
            title: 'Reset Password',
        },
        {
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            title: 'Logout',
        },
    ];
    const handleMenuAuthChange = (item) => {
        if (item.title == 'Reset Password') {
            handleResetPasswordShow();
        } else if (item.title == 'Logout') {
            handleLogout();
        }
    };

    return (
        <Fragment>
            <div className={cx('main')}></div>
            <Navbar key={expand} expand={expand} className={cx('wrapper')}>
                <Container fluid>
                    <Navbar.Brand>
                        <span className={cx('main-title', 'fs-4')} onClick={handleGoHome}>
                            <img className={cx('icon-main')} src={images.favicon} />{' '}
                            <span className={cx('fw-normal', 'text-uppercase')}>ITSHARING </span>
                        </span>
                        {!isAd ? (
                            <Fragment>
                                <span className={cx('fs-6', 'fw-light')}>Never stop trying</span>
                            </Fragment>
                        ) : (
                            <span
                                className={cx('fs-6', 'text-primary', 'main-title')}
                                onClick={() => handleGoModeAdmin()}
                            >
                                You're {!isAdminMode() ? 'Guest' : 'Admin'}
                            </span>
                        )}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <span className={cx('main-title', 'fs-4')} onClick={handleGoHome}>
                                    <img className={cx('icon-main')} src={images.favicon} /> ITSHARING{' '}
                                </span>
                                <span className={cx('fs-6', 'fw-light')}>Never stop trying</span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {!isMobile ? (
                                <Search keyword={searchKeyword} />
                            ) : (
                                <Form className="d-flex">
                                    <Form.Control
                                        name="search_new"
                                        type="search"
                                        placeholder="Input somethings"
                                        aria-label="Search"
                                        onKeyDown={handleEnterNew}
                                        autoComplete="off"
                                        onChange={(e) => setSearchKeyword(e.target.value)}
                                        value={searchKeyword}
                                    />
                                </Form>
                            )}
                            <Nav className="justify-content-end flex-grow-1">
                                {!isAdminMode() ? (
                                    <Fragment>
                                        <Nav.Link
                                            className={cx('new-link', pathName == config.routes.home ? 'active' : '')}
                                            onClick={handleGoHome}
                                        >
                                            <FontAwesomeIcon className={cx('icon')} icon={faTv} /> <span>Home</span>
                                        </Nav.Link>
                                        <Nav.Link
                                            className={cx(
                                                'new-link',
                                                pathName == config.routes.aboutMe ? 'active' : '',
                                            )}
                                            onClick={handleGoAboutMe}
                                        >
                                            <FontAwesomeIcon className={cx('icon')} icon={faUser} />{' '}
                                            <span>About me</span>
                                        </Nav.Link>
                                        <Nav.Link
                                            className={cx('new-link', pathName == config.routes.new ? 'active' : '')}
                                            onClick={handleGoNew}
                                        >
                                            <FontAwesomeIcon className={cx('icon')} icon={faNewspaper} />{' '}
                                            <span>News</span>
                                        </Nav.Link>
                                        <Menu items={MENU_ITEMS}>
                                            <Nav.Link className={cx('new-link')}>
                                                <FontAwesomeIcon className={cx('icon')} icon={faCaretDown} />{' '}
                                                <span>More</span>
                                            </Nav.Link>
                                        </Menu>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Nav.Link
                                            className={cx(
                                                'new-link',
                                                cx(pathName == config.routes.adminNew ? 'active' : ''),
                                            )}
                                            onClick={handleGoAdminNew}
                                        >
                                            <FontAwesomeIcon className={cx('icon')} icon={faNewspaper} />{' '}
                                            <span>Ad News</span>
                                        </Nav.Link>
                                    </Fragment>
                                )}
                                {!isLogin() ? (
                                    <Fragment>
                                        <Nav.Link className={cx('me-1', 'new-link')} onClick={handleLoginShow}>
                                            <span className={cx('text-primary')}>Login</span>
                                        </Nav.Link>
                                        <Nav.Link className={cx('me-1', 'new-link')} onClick={handleSignUpShow}>
                                            <span className={cx('text-primary')}>Signup</span>
                                        </Nav.Link>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Menu items={MENU_AUTH_ITEMS} onChange={handleMenuAuthChange}>
                                            <Nav.Link className={cx('new-link')}>
                                                <FontAwesomeIcon className={cx('icon')} icon={faCaretDown} />{' '}
                                                <span>
                                                    Hi, {auth.short_name ? auth.short_name.substring(0, 10) : 'No name'}
                                                </span>
                                            </Nav.Link>
                                        </Menu>
                                    </Fragment>
                                )}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                <HeaderLogin
                    loginValidated={loginValidated}
                    setLoginValidated={setLoginValidated}
                    showLogin={showLogin}
                    setShowLogin={setShowLogin}
                />
                <HeaderSignup
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                    signupValidated={signupValidated}
                    setSignupValidated={setSignupValidated}
                />
                <HeaderResetPassword
                    validated={resetPasswordValidated}
                    setValidated={setResetPasswordValidated}
                    showModal={showResetPasswordModal}
                    setShowModal={setShowResetPasswordModal}
                />
            </Navbar>
        </Fragment>
    );
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { check: stage.common.check, auth: stage.auth.user },
    };
}
export default connect(mapStageToProp)(Header);
