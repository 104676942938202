import { useState, Fragment, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';

import { store } from '~/provider/store';
import { get, post } from '~/utils/httpRequest';
import { authAction } from '~/provider/actions';
import styles from './HeaderLogin.module.scss';
import apiStatus from '~/config/apiStatus';

const cx = classNames.bind(styles);

function HeaderLogin({ loginValidated, showLogin, setLoginValidated, setShowLogin }) {
    const submitLoginRef = useRef();

    const [showMsg, setShowMsg] = useState(false);
    const [isLoginLoading, setLoginLoading] = useState(false);
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [googleCaptcha, setGoogleCaptcha] = useState(false);

    const googleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY;

    const handleLogin = () => {
        submitLoginRef.current.click();
    };
    const handleLoginSubmit = async (event) => {
        const form = event.currentTarget;
        let loginValidatedTerm = loginValidated;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            loginValidatedTerm = true;
            setLoginValidated(loginValidatedTerm);
        } else {
            // Check repeat password
            if (!googleCaptcha) {
                setShowMsg('Please verify your captcha.');
                return;
            }

            loginValidatedTerm = false;
            setLoginValidated(loginValidatedTerm);
        }

        if (!loginValidatedTerm) {
            setLoginLoading(true);

            const res = await post(
                '/user/login',
                {
                    email: emailLogin,
                    password: passwordLogin,
                    token: googleCaptcha,
                },
                {},
                { aes: true },
            );

            if (res.status == apiStatus.success) {
                setEmailLogin('');
                setPasswordLogin('');
                setShowLogin(false);
                setLoginValidated(false);
                setGoogleCaptcha(false);
                setShowMsg(false);

                store.dispatch(authAction.updateAuthSaga({ short_name: res.data.short_name, role: res.data.role }));
            } else {
                setGoogleCaptcha(false);
                setShowMsg(res.msg);
            }

            setLoginLoading(false);
        }
    };
    const handleLoginClose = () => {
        setEmailLogin('');
        setPasswordLogin('');
        setShowLogin(false);
        setShowMsg(false);
        setGoogleCaptcha(false);
    };
    const handleCaptchaChange = (value) => {
        setGoogleCaptcha(value);
    };

    return (
        <Fragment>
            <Modal show={showLogin} onHide={handleLoginClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={loginValidated} onSubmit={handleLoginSubmit} id="signin">
                        <Form.Group className="mb-3" controlId="validationCustom01">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={emailLogin}
                                onChange={(e) => {
                                    setEmailLogin(e.target.value);
                                }}
                                required
                                autoComplete="off"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                This field cannot be empty or format is different with email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="validationCustom02">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={passwordLogin}
                                onChange={(e) => {
                                    setPasswordLogin(e.target.value);
                                }}
                                required
                                autoComplete="off"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">This field cannot be empty</Form.Control.Feedback>
                            {showMsg && (
                                <Alert
                                    className={cx('mt-3')}
                                    variant="danger"
                                    onClose={() => setShowMsg(false)}
                                    dismissible
                                >
                                    {showMsg}
                                </Alert>
                            )}
                            <Button ref={submitLoginRef} variant="primary" type="submit" hidden></Button>
                        </Form.Group>
                    </Form>
                    {!isLoginLoading && <ReCAPTCHA sitekey={googleSiteKey} onChange={handleCaptchaChange} />}
                    <div className={cx('pt-3')}>
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faExpeditedssl} /> SSL
                        </span>
                        {' | '}
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faShieldAlt} /> AES
                        </span>
                        {' | '}
                        <span className={cx('text-success')}>
                            <FontAwesomeIcon className={cx('icon')} icon={faGoogle} /> Google Captcha
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={!isLoginLoading ? handleLogin : null}
                        disabled={isLoginLoading}
                    >
                        {isLoginLoading ? 'Loading…' : 'Login'}
                    </Button>
                    {!isLoginLoading && (
                        <Button variant="outline-secondary" onClick={handleLoginClose}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default HeaderLogin;
