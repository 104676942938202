import { useState, useEffect, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames/bind';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';

import { get } from '~/utils/httpRequest';
import styles from './New.module.scss';
import NewItem from '~/components/NewItem';
import images from '~/assets/images';
import Loading from '~/components/Loading';

const cx = classNames.bind(styles);

function New() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [importantNews, setImportantNews] = useState([]);
    const [jocelynNews, setJocelynNews] = useState([]);
    const [news, setNews] = useState([]);

    const [type, setType] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);
    const [availablePages, setAvailablePages] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');

    const [loadingImportantNews, setLoadingImportantNews] = useState(true);
    const [loadingJocelynNews, setLoadingJocelynNews] = useState(true);
    const [loadingNews, setLoadingNews] = useState(true);

    useEffect(() => {
        getImportantNews();
        getJocelynNews();
    }, []);
    useEffect(() => {
        let searchKeywordTerm = searchParams.get('q') ? searchParams.get('q') : '';
        let pageTerm = searchParams.get('page') ? searchParams.get('page') : 1;
        let typeTerm = searchParams.get('type') ? searchParams.get('type') : '';

        setSearchKeyword(searchKeywordTerm);
        setPage(pageTerm);
        setType(typeTerm);

        getAllNews({
            keyword: searchKeywordTerm,
            page: pageTerm,
            type: typeTerm,
        });
    }, [searchParams]);

    const getImportantNews = async () => {
        let responseNews = await get('/new', { page: 1, limit: 2, type: 'important', no_count_list: true });
        setImportantNews(responseNews.data);
        setLoadingImportantNews(false);
    };
    const getJocelynNews = async () => {
        let responseNews = await get('/new', { page: 1, limit: 3, type: 'jocelyn_skill', no_count_list: true });
        setJocelynNews(responseNews.data);
        setLoadingJocelynNews(false);
    };
    const getAllNews = async (parameters = {}) => {
        setLoadingNews(true);
        let responseNews = await get('/new', {
            page: parameters.page,
            limit: limit,
            keyword: parameters.keyword,
            type: parameters.type,
        });
        let lastPage = Math.ceil(responseNews.count / limit);
        let currentPage = responseNews.current_page;
        let availablePages = [];

        if (currentPage - 2 > 0) {
            availablePages.push(currentPage - 2);
        }
        if (currentPage - 1 > 0) {
            availablePages.push(currentPage - 1);
        }
        availablePages.push(currentPage);
        if (currentPage + 1 <= lastPage) {
            availablePages.push(currentPage + 1);
        }
        if (currentPage + 2 <= lastPage) {
            availablePages.push(currentPage + 2);
        }

        setAvailablePages(availablePages);
        setLastPage(lastPage);
        setPage(currentPage);
        setNews(responseNews.data);
        setLoadingNews(false);
    };
    const isFilter = () => {
        return type || searchKeyword ? true : false;
    };

    return (
        <Container fluid={true} className={cx('wrapper')}>
            {!isFilter() && (
                <Fragment>
                    <h4 className={cx('wrapper-title', 'p-2', 'mb-1')}>Important News & Jocelyn's Skills</h4>
                    <Row>
                        {loadingImportantNews ? (
                            <Col md={6}>
                                <Loading />
                            </Col>
                        ) : (
                            <Col md={6} className={cx('pe-0')}>
                                {importantNews && importantNews[0] && (
                                    <div className={cx('cv', 'p-2', 'mb-1')}>
                                        <NewItem
                                            id={importantNews[0].new_id}
                                            main={true}
                                            title={importantNews[0].new_title}
                                            content={importantNews[0].new_content}
                                            content_plain_text={importantNews[0].new_content_plain_text}
                                            author={importantNews[0].author_short_name}
                                            createdDate={importantNews[0].created_date}
                                            image={
                                                importantNews[0].image_url
                                                    ? JSON.parse(importantNews[0].image_url)[0]['web_path']
                                                    : images.noImage
                                            }
                                        />
                                    </div>
                                )}
                                {importantNews && importantNews[1] && (
                                    <div className={cx('cv', 'p-2')}>
                                        <NewItem
                                            id={importantNews[1].new_id}
                                            title={importantNews[1].new_title}
                                            content={importantNews[1].new_content}
                                            content_plain_text={importantNews[1].new_content_plain_text}
                                            author={importantNews[1].author_short_name}
                                            createdDate={importantNews[1].created_date}
                                            image={
                                                importantNews[1].image_url
                                                    ? JSON.parse(importantNews[1].image_url)[0]['web_path']
                                                    : images.noImage
                                            }
                                        />
                                    </div>
                                )}
                            </Col>
                        )}
                        {loadingJocelynNews ? (
                            <Col md={6}>
                                <Loading />
                            </Col>
                        ) : (
                            <Col md={6} className={cx('ps-1')}>
                                {jocelynNews &&
                                    jocelynNews.map((item, index) => {
                                        return (
                                            <div key={index} className={cx('cv', 'p-2', 'mb-1')}>
                                                <NewItem
                                                    id={item.new_id}
                                                    title={item.new_title}
                                                    content={item.new_content}
                                                    content_plain_text={item.new_content_plain_text}
                                                    author={item.author_short_name}
                                                    createdDate={item.created_date}
                                                    image={
                                                        item.image_url
                                                            ? JSON.parse(item.image_url)[0]['web_path']
                                                            : images.noImage
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                            </Col>
                        )}
                    </Row>
                </Fragment>
            )}
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>
                All News{' '}
                {searchKeyword && (
                    <span className={cx('fs-6', 'fw-light')}>Searching with keyword: {searchKeyword}</span>
                )}
            </h4>
            <Row>
                <Col md={6} className={cx('pe-0', 'pb-1')}>
                    <Form.Select
                        value={type}
                        style={{ width: '200px' }}
                        onChange={(e) => setSearchParams({ page: 1, q: searchKeyword, type: e.target.value })}
                    >
                        <option value="">All</option>
                        <option value="jocelyn_skill">Jocelyn Skill</option>
                        <option value="important">Important</option>
                        <option value="normal">Normal</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                {news.length > 0 ? (
                    <Fragment>
                        <Col md={6} className={cx('pe-0')}>
                            {news &&
                                news.map((item, index) => {
                                    if (index < 4) {
                                        return (
                                            <div key={index} className={cx('cv', 'p-2', 'mb-1')}>
                                                <NewItem
                                                    id={item.new_id}
                                                    title={item.new_title}
                                                    content={item.new_content}
                                                    content_plain_text={item.new_content_plain_text}
                                                    author={item.author_short_name}
                                                    createdDate={item.created_date}
                                                    image={
                                                        item.image_url
                                                            ? JSON.parse(item.image_url)[0]['web_path']
                                                            : images.noImage
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                })}
                        </Col>
                        <Col md={6} className={cx('ps-1')}>
                            {news &&
                                news.map((item, index) => {
                                    if (index >= 4) {
                                        return (
                                            <div key={index} className={cx('cv', 'p-2', 'mb-1')}>
                                                <NewItem
                                                    id={item.new_id}
                                                    title={item.new_title}
                                                    content={item.new_content}
                                                    content_plain_text={item.new_content_plain_text}
                                                    author={item.author_short_name}
                                                    createdDate={item.created_date}
                                                    image={
                                                        item.image_url
                                                            ? JSON.parse(item.image_url)[0]['web_path']
                                                            : images.noImage
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                })}
                        </Col>
                    </Fragment>
                ) : (
                    <div className={cx('text-center', 'p-2')}>No data</div>
                )}
                {loadingNews && (
                    <Col md={12}>
                        <Loading />
                    </Col>
                )}
            </Row>
            {news.length > 0 && (
                <div className={cx('d-flex', 'justify-content-center', 'cv', 'p-2', 'mb-1')}>
                    <Pagination>
                        <Pagination.First onClick={() => setSearchParams({ page: 1, q: searchKeyword, type: type })} />
                        {/* <Pagination.Prev /> */}

                        {availablePages &&
                            availablePages.map((item, index) => {
                                return (
                                    <Pagination.Item
                                        key={index}
                                        active={item == page ? 'active' : ''}
                                        onClick={() => setSearchParams({ page: item, q: searchKeyword, type: type })}
                                    >
                                        {item}
                                    </Pagination.Item>
                                );
                            })}

                        {/* <Pagination.Next /> */}
                        <Pagination.Last
                            onClick={() => setSearchParams({ page: lastPage, q: searchKeyword, type: type })}
                        />
                    </Pagination>
                </div>
            )}
        </Container>
    );
}

export default New;
