import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useRef } from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';

import styles from './ImageBase64.module.scss';

const cx = classNames.bind(styles);

function ImageBase64({ onChange = () => {}, file = false, type = 'image/*' }) {
    const [currentFile, setCurrentFile] = useState(file);
    const inputRef = useRef();

    useEffect(() => {
        if (file == false) {
            inputRef.current.value = null;
        }
        setCurrentFile(file);
    }, [file]);

    const toDataURL = (url) => {
        return fetch(url)
            .then((response) => response.blob())
            .then(
                (blob) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    }),
            );
    };
    const handleChange = async (e) => {
        let fileTerm = e.target.files[0] ? e.target.files[0] : false;
        let base64 = '';

        if (fileTerm) {
            let url = URL.createObjectURL(fileTerm);
            if (url) {
                base64 = await toDataURL(url);
                fileTerm.base64 = base64;
                URL.revokeObjectURL(url);
            }
        }
        setCurrentFile(fileTerm);

        onChange(fileTerm);
    };
    const handleDeleteImage = () => {
        onChange(false);
    };

    return (
        <div>
            <Form.Control type="file" ref={inputRef} onChange={handleChange} accept={type} />
            {currentFile && (
                <div className={cx('wrapper-img', 'mt-1')}>
                    <Image src={currentFile.base64} rounded />{' '}
                    <FontAwesomeIcon
                        onClick={handleDeleteImage}
                        className={cx('icon', 'text-danger')}
                        icon={faCircleXmark}
                    />{' '}
                    <br></br>
                    {currentFile.name}
                </div>
            )}
        </div>
    );
}

export default ImageBase64;
