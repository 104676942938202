import { Fragment } from 'react';
import { Image } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './AboutMe.module.scss';
import images from '~/assets/images';
import Cv from '~/components/Cv';

const cx = classNames.bind(styles);

function Home() {
    return (
        <Fragment>
            <div className={cx('parent-wrapper')}>
                <div className={cx('wrapper')}>
                    <Image src={images.aboutMeAvt} className={cx('card-img')} roundedCircle />
                </div>
                <div className={cx('cv', 'sub-title', 'fs-3')}>
                    Fullstack developer, Solfware Engineer, Web developer, Coder
                    <br></br>
                    <span className={cx('fs-5', 'text-secondary')}>A winner never stops trying</span>
                </div>
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Prologue</h4>
            <div className={cx('wrapper-content', 'p-2', 'mt-1', 'text-center', 'fst-normal')}>
                <i>Dear you, my friend</i>,<br></br>
                <br></br>
                First of all,
                <br></br>I would like to send my best regards to you,
                <br></br>
                <br></br>
                I'm Khoa, my nickname is Jocelyn,
                <br></br>
                My strong skills are <b>PHP, ReactJS, Javascript, Jquery</b>,<br></br>
                With 5 years experience and many different positions, I can resolve many issues or difficult situations
                in your company,
                <br></br>
                Just send me a message, you'll realize that we can do it together,
                <br></br>
                You know how to contact me, right?
                <br></br>
                <b>Phone or Zalo “+84 338 050 156” or email “storetech096@gmail.com”</b>,<br></br>
                <br></br>
                <i>See you soon, my friend!</i>
                <br></br>
                <i>Jocelyn.</i>
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Curriculum Vitae</h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <Cv />
            </div>
        </Fragment>
    );
}

export default Home;
