import * as commonSaga from './common';
import * as authSaga from './auth';

import { all, fork } from 'redux-saga/effects';

const { updateCheckFork, updateCheckApiFork } = commonSaga;
const { updatAuthFork } = authSaga;

export function* initSaga() {
    yield all([yield fork(updateCheckFork), yield fork(updateCheckApiFork), yield fork(updatAuthFork)]);
}
