import { Fragment, memo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { get, post } from '~/utils/httpRequest';
import styles from './SmallNew.module.scss';
import SmallNewItem from '~/components/SmallNewItem';
import Loading from '~/components/Loading';

const cx = classNames.bind(styles);

function SmallNew({ title = '', apiType = '', seeMoreUrl = '/new' }) {
    const navigate = useNavigate();

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNews();
    }, []);

    const getNews = async () => {
        let responseNews = await get('/new', { page: 1, limit: 4, type: apiType, no_count_list: true });
        setNews(responseNews.data);
        setLoading(false);
    };

    const handleGoNew = () => {
        navigate(seeMoreUrl);
    };

    return (
        <div className={cx('wrapper', 'p-2')}>
            <div className={cx('fs-5', 'pb-2')}>
                <FontAwesomeIcon icon={faBookmark} /> <span>{title}</span>
            </div>
            {loading ? (
                <Loading />
            ) : news.length > 0 ? (
                <Fragment>
                    {news.map((item, index) => {
                        let imageUrlTerm = false;
                        if (item.image_url) {
                            imageUrlTerm = JSON.parse(item.image_url);
                        }
                        return (
                            <SmallNewItem
                                key={index}
                                title={item.new_title}
                                image={imageUrlTerm ? imageUrlTerm[0]['web_path'] : ''}
                                createdDate={item.created_date}
                                id={item.new_id}
                            />
                        );
                    })}
                    <div className={cx('see-more', 'text-primary')} onClick={handleGoNew}>
                        See more
                    </div>
                </Fragment>
            ) : (
                <div className={cx('text-center')}>No data</div>
            )}
        </div>
    );
}

export default memo(SmallNew);
