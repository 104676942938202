import React, { useState, Fragment, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import classNames from 'classnames/bind';

import { get } from '~/utils/httpRequest';
import styles from './Cv.module.scss';
import HeaderLogin from '~/layouts/components/HeaderLogin';

const cx = classNames.bind(styles);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Cv(props) {
    const [loginValidated, setLoginValidated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [show, setShow] = useState(false);
    const [urlPdf, setUrlPdf] = useState(false);

    const auth = props.commonData.auth;

    useEffect(() => {
        if (auth) {
            getUrlPdf();
        }
    }, [auth]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const handleNextPrevius = (type) => {
        if (type == 'next') {
            if (pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
            }
        } else {
            if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            }
        }
    };
    const getUrlPdf = async () => {
        let response = await get('/user/get-url-cv', {});
        let url = 'data:application/pdf;base64,' + response.data;
        setUrlPdf(url);
    };
    const handleLoginShow = () => {
        setLoginValidated(false);
        setShowLogin(true);
    };

    return (
        <div className={cx(isMobile && auth ? '' : 'wrapper')}>
            {!auth || !urlPdf ? (
                <div className={cx('text-center')}>
                    <div className={cx('need-login', 'p-3')}>
                        <div className={cx('mb-2')}>You need to login to see this content.</div>
                        <Button variant="outline-primary" onClick={handleLoginShow}>
                            Login now
                        </Button>
                    </div>
                </div>
            ) : (
                <div className={cx('d-flex', 'justify-content-center')}>
                    <div>
                        <Document
                            className={cx('cv-wrapper')}
                            file={urlPdf}
                            onLoadSuccess={onDocumentLoadSuccess}
                            type="application/pdf"
                        >
                            <Page pageNumber={pageNumber} width={isMobile ? 370 : 700} />
                        </Document>
                        <div className={cx('text-center', 'mt-2')}>
                            <span className={cx('next-prev')} onClick={() => handleNextPrevius('prev')}>
                                <FontAwesomeIcon icon={faCircleChevronLeft} />
                            </span>
                            <span>
                                {' '}
                                Page {pageNumber} of {numPages}{' '}
                            </span>
                            <span className={cx('next-prev')} onClick={() => handleNextPrevius('next')}>
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                            </span>
                        </div>
                        <div className={cx('text-center', 'mt-2', 'mb-2')}>
                            <Button variant="outline-primary" size="sm" onClick={handleShow}>
                                Dowload
                            </Button>{' '}
                            <Button variant="outline-success" size="sm" onClick={handleShow}>
                                View Fullscreen
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>This feature is updating, please come back here later.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <HeaderLogin
                loginValidated={loginValidated}
                setLoginValidated={setLoginValidated}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
        </div>
    );
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { auth: stage.auth.user },
    };
}
export default connect(mapStageToProp)(Cv);
