import classNames from 'classnames/bind';

import styles from './Poppy.module.scss';

const cx = classNames.bind(styles);

function Poppy() {
    return (
        <div>
            <div className={cx('steam', 'steam1')}> </div>
            <div className={cx('steam', 'steam2')}> </div>
            <div className={cx('steam', 'steam3')}> </div>
            <div className={cx('steam', 'steam4')}> </div>
        </div>
    );
}

export default Poppy;
