import { connect } from 'react-redux';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { isAdmin } from '~/services/authService';

function CheckAuth({ children, commonData }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdmin()) {
            navigate('/');
        }
    });

    return <Fragment>{children}</Fragment>;
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { auth: stage.auth.user },
    };
}

export default connect(mapStageToProp)(CheckAuth);
