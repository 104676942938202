import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames/bind';

import { get } from '~/utils/httpRequest';
import images from '~/assets/images';
import styles from './Profile.module.scss';

const cx = classNames.bind(styles);

function Profile(props) {
    const navigate = useNavigate();

    const auth = props.commonData.auth;
    const [userProfile, setUserProfile] = useState({});

    useEffect(() => {
        getUserProfile();
    }, []);

    useEffect(() => {
        if (!auth) {
            navigate('/');
        }
    }, [auth]);

    const getUserProfile = async () => {
        let responseUser = await get('/user', {});
        setUserProfile(responseUser.data);
    };

    return (
        <Container fluid={true} className={cx('wrapper')}>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Profile</h4>
            <Row>
                <Col md={12}>
                    <div className={cx('cv', 'p-2', 'mb-1')}>
                        <Row>
                            <Col md={3} className={cx('text-center')}>
                                <Image src={images.noImage} className={cx('avt')} roundedCircle />
                                <br></br>
                                <span className={cx('text-secondary')}>Jocelyn</span>
                            </Col>
                            <Col md={9}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control type="text" readOnly value={userProfile.full_name || ''} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Short Name</Form.Label>
                                        <Form.Control type="text" readOnly value={userProfile.short_name || ''} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" readOnly value={userProfile.email || ''} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Label>Verify Status</Form.Label>
                                        <Form.Control
                                            type="text"
                                            readOnly
                                            value={userProfile.verify_date ? 'ACTIVE' : 'UNACTIVE'}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput5">
                                        <Form.Label>Created Date ( UTC )</Form.Label>
                                        <Form.Control type="text" readOnly value={userProfile.created_date || ''} />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

// Stage
function mapStageToProp(stage) {
    return {
        commonData: { auth: stage.auth.user },
    };
}

export default connect(mapStageToProp)(Profile);
