import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Home.module.scss';
import WorkingHistory from '~/components/WorkingHistory';
import Cv from '~/components/Cv';
import MySkill from '~/components/MySkill';

const cx = classNames.bind(styles);

function Home() {
    const navigate = useNavigate();

    const handleGoAboutMe = () => {
        navigate('/about-me');
    };

    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1')}>Jocelyn's Skills</h4>
            <MySkill />
            <div className={cx('wrapper-content', 'p-2', 'mt-1', 'text-center')}>
                To get more informations about me, please click{' '}
                <span className={cx('link', 'fs-5', 'text-primary')} onClick={handleGoAboutMe}>
                    Here
                </span>
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Personal Biography</h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <WorkingHistory />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Curriculum Vitae</h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <Cv />
            </div>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>Important Note</h4>
            <div className={cx('wrapper-content', 'p-2', 'mt-1', 'text-center')}>
                We don't offer any payment, this site was created for free using or free purposes at this time, if you
                have any offer for payment, it's a liar or a cheat,{' '}
                <b>PLEASE DO NOT TRANSFER, PAY OR FOLLOW WHAT THEY SAY</b>.
            </div>
        </Fragment>
    );
}

export default Home;
