import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './DefaultLayout.module.scss';
import Header from '../components/Header';
import LeftBar from '../components/LeftBar';
import Banner from '../components/Banner';
import Footer from '../components/Footer/Footer';
import MoveToTop from '~/components/MoveToTop';
import LeftNotification from '~/components/LeftNotification';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    return (
        <div className={cx('wrapper')}>
            <Header />
            <div className={cx('d-flex', 'bd-highlight')}>
                <div className={cx('bd-highlight')}>
                    <LeftBar />
                </div>
                <div className={cx('flex-fill', 'bd-highlight')}>
                    <div className={cx('content', 'mt-1', 'me-1', 'mb-1')}>
                        <Banner />
                        <div className={cx('m-1')}></div>
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
            <LeftNotification />
            <MoveToTop />
        </div>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
