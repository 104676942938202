import { authType } from '../types';
import { authAction } from '../actions';

import { put, call, takeLeading } from 'redux-saga/effects';

// Fork
export function* updatAuthFork() {
    yield takeLeading(authType.updateAuthSaga, updateAuthSaga);
}

// Saga
function* updateAuthSaga(action) {
    yield put(authAction.updateAuth(action.payload));
}

// Services
