import { authType } from '../types';

const initStage = {
    user: '',
};

const authReducer = (stage = initStage, action) => {
    switch (action.type) {
        case authType.updateAuth:
            return {
                ...stage,
                user: action.payload,
            };
        default:
            return stage;
    }
};

export default authReducer;
