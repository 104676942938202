import { store } from '~/provider/store';
import { authAction } from '~/provider/actions';
import { get } from '~/utils/httpRequest';

const role = { guest: 'GUEST', admin: 'ADMIN' };

const commonData = () => {
    const stage = store.getState();
    const auth = stage.auth.user;

    return { auth: auth };
};
const isLogin = () => {
    const auth = commonData().auth;

    return auth ? true : false;
};
const isAdmin = () => {
    const auth = commonData().auth;

    if (auth.role && auth.role == role.admin) {
        return true;
    } else {
        return false;
    }
};
const isAdminMode = () => {
    const auth = commonData().auth;

    return auth.is_admin_mode;
};
const resetAdminToGuest = () => {
    const auth = commonData().auth;

    auth.role = role.guest;
    delete auth.is_admin_mode;
    store.dispatch(authAction.updateAuthSaga(auth));
};
const logout = async ({ isDeleteCookie = false }) => {
    if (isDeleteCookie) {
        await get('/user/logout', {});
    }
    store.dispatch(authAction.updateAuthSaga(''));
};
const setModeAdminReverse = () => {
    const auth = commonData().auth;

    if (auth.is_admin_mode) {
        delete auth.is_admin_mode;
    } else {
        auth.is_admin_mode = true;
    }

    store.dispatch(authAction.updateAuthSaga(auth));
};
const getAuth = () => {
    const auth = commonData().auth;

    return auth;
};

export { role, isAdmin, resetAdminToGuest, isLogin, logout, setModeAdminReverse, isAdminMode, getAuth };
