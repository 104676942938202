import config from '~/config';

// Layouts
import NoBannerLayout from '~/layouts/NoBannerLayout';

// Pages
import Home from '~/pages/Home';
import AboutMe from '~/pages/AboutMe';
import New from '~/pages/New';
import Test from '~/pages/Test';
import CurriculumVitae from '~/pages/CurriculumVitae';
import Share from '~/pages/Share';
import ShowNew from '~/pages/ShowNew';
import AdminNew from '~/pages/Admin/AdminNew';
import AdminNewCreate from '~/pages/Admin/AdminNewCreate';
import AdminNewEdit from '~/pages/Admin/AdminNewEdit';
import Profile from '~/pages/Profile';

// Public routes
const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.aboutMe, component: AboutMe, layout: NoBannerLayout },
    { path: config.routes.new, component: New, layout: NoBannerLayout },
    // { path: config.routes.test, component: Test, layout: NoBannerLayout },
    { path: config.routes.cv, component: CurriculumVitae, layout: NoBannerLayout },
    { path: config.routes.share, component: Share, layout: NoBannerLayout },
    { path: config.routes.showNew, component: ShowNew, layout: NoBannerLayout },
    { path: config.routes.profile, component: Profile, layout: NoBannerLayout },
];

const privateRoutes = [
    { path: config.routes.adminNew, component: AdminNew, layout: NoBannerLayout },
    { path: config.routes.adminNewStore, component: AdminNewCreate, layout: NoBannerLayout },
    { path: config.routes.adminNewEdit, component: AdminNewEdit, layout: NoBannerLayout },
];

export { publicRoutes, privateRoutes };
