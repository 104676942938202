import { useState } from 'react';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

function Footer() {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleGoToPage = (type) => {
        if (type == 'facebook') {
            window.open('https://www.facebook.com/profile.php?id=61557148625741');
        } else if (type == 'twitter') {
            window.open('https://twitter.com/storetech096');
        } else if (type == 'telegram') {
            window.open('https://telegram.me/@storetech096');
        } else if (type == 'instagram') {
            window.open('https://www.instagram.com/storetech096');
        } else if (type == 'tiktok') {
            window.open('https://www.tiktok.com/@_storetech096');
        } else if (type == 'youtube') {
            window.open('https://www.youtube.com/channel/UCbeqco_tXeVY2V2h7LLE16Q');
        } else if (type == 'general_condition') {
            navigate('/new/9bb62407-67a5-4891-90d5-279174aef4f4');
        } else if (type == 'warranty_policy') {
            navigate('/new/9be49eed-28fc-4fa1-b841-22082013ee45');
        } else if (type == 'supporting_policies') {
            navigate('/new/9be5e8e0-24f8-4369-b1ef-3a159b589abf');
        } else if (type == 'trading_conditions') {
            navigate('/new/9be5ea1c-3961-4b63-84bb-c9508f0ef0ff');
        } else if (type == 'introducing') {
            navigate('/about-me');
        } else if (type == 'home') {
            navigate('/');
        } else if (type == 'cv') {
            navigate('/cv');
        } else if (type == 'share') {
            navigate('/share');
        }
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('d-flex', 'justify-content-center')}>
                <span className={cx('icon-wrapper')} onClick={() => handleGoToPage('facebook')}>
                    <FontAwesomeIcon className={cx('icon')} icon={faFacebook} />
                </span>
                <span className={cx('icon-wrapper')} onClick={() => handleGoToPage('twitter')}>
                    <FontAwesomeIcon className={cx('icon')} icon={faTwitter} />
                </span>
                <span className={cx('icon-wrapper')} onClick={() => handleGoToPage('telegram')}>
                    <FontAwesomeIcon className={cx('icon')} icon={faTelegram} />
                </span>
                <span className={cx('icon-wrapper')} onClick={() => handleGoToPage('tiktok')}>
                    <FontAwesomeIcon className={cx('icon')} icon={faTiktok} />
                </span>
                <span className={cx('icon-wrapper')} onClick={() => handleGoToPage('youtube')}>
                    <FontAwesomeIcon className={cx('icon')} icon={faYoutube} />
                </span>
            </div>
            <div className={cx('mt-4')}>
                <Container>
                    <Row className={cx('justify-content-xl-center')}>
                        <Col xl lg="2" className={cx('sign-up-title', 'me-2', 'p-0', 'fs-5')}>
                            Sign up for our newsletter
                        </Col>
                        <Col xl="auto" className={cx('me-2', 'p-0')}>
                            <Form.Control
                                name="email_address"
                                type="text"
                                placeholder="Email Address"
                                className={cx('sign-up-email')}
                            />
                        </Col>
                        <Col xl lg="2" className={cx('p-0')}>
                            <Button variant="secondary" className={cx('sign-up-subcribe')} onClick={handleShow}>
                                SUBCRIBE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={cx('describe', 'mt-4')}>
                No one can make you happy until you're happy with yourself first.
            </div>
            <div className={cx('more-info', 'mt-4')}>
                <Container>
                    <Row>
                        <Col md={3}>
                            <h5 className={cx('text-white', 'bg-secondary', 'text-center')}>Support</h5>
                            <ul className={cx('list-unstyled')}>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('share')}
                                    >
                                        Courses
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('share')}
                                    >
                                        Sharing Experience
                                    </span>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5 className={cx('text-white', 'bg-secondary', 'text-center')}>Policy</h5>
                            <ul className={cx('list-unstyled')}>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('general_condition')}
                                    >
                                        General conditions
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('warranty_policy')}
                                    >
                                        Warranty Policy
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('supporting_policies')}
                                    >
                                        Supporting policies
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('trading_conditions')}
                                    >
                                        Trading conditions
                                    </span>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5 className={cx('text-white', 'bg-secondary', 'text-center')}>Jocelyn's Information</h5>
                            <ul className={cx('list-unstyled')}>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('introducing')}
                                    >
                                        Introducing
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('home')}
                                    >
                                        Skills
                                    </span>
                                </li>
                                <li>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className={cx('text-primary')}
                                        onClick={() => handleGoToPage('cv')}
                                    >
                                        Curriculum Vitae
                                    </span>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5 className={cx('text-white', 'bg-secondary', 'text-center')}>Contact</h5>
                            <ul className={cx('list-unstyled')}>
                                <li>Phone: +84 338 050 156</li>
                                <li>Zalo: +84 338 050 156</li>
                                <li>Email: storetech096@gmail.com</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={cx('copy-right')}>
                <span className={cx('fs-6')}>©2024 copyright: itsharing.io.vn</span>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>This feature is updating</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Footer;
