import { Fragment, useState, useRef, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { convert } from 'html-to-text';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-bootstrap/Alert';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames/bind';
import Modal from 'react-bootstrap/Modal';

import { get, put } from '~/utils/httpRequest';
import styles from './AdminNewEdit.module.scss';
import apiStatus from '~/config/apiStatus';
import ImageBase64 from '~/components/ImageBase64';

const cx = classNames.bind(styles);

function AdminNewEdit() {
    const { id } = useParams();
    const submitLoginRef = useRef();
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [isLoginLoading, setLoginLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [type, setType] = useState('normal');
    const [contentPlainText, setContentPlainText] = useState('');
    const [fileSubmit, setFileSubmit] = useState({});
    const [fileDeleteSubmit, setFileDeleteSubmit] = useState({});
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');

    useEffect(() => {
        getNew(id);
    }, []);

    const getNew = async (id) => {
        let responseNews = await get('/new/' + id, {});
        if (responseNews.status == apiStatus.success) {
            let newData = responseNews.data;
            setTitle(newData.new_title);
            setType(newData.new_type);
            setContent(newData.new_content);
            setContentPlainText(newData.new_content_plain_text);
            if (newData.image_url) {
                let fileSubmitTerm = {};
                let newImageData = JSON.parse(newData.image_url);

                newImageData.forEach((item, index) => {
                    fileSubmitTerm[index] = {
                        base64: item.web_path,
                        id: item.file_id,
                    };
                });

                setFileSubmit(fileSubmitTerm);
            }
        } else {
            navigate('/admin/new');
        }
    };
    const handleSave = () => {
        submitLoginRef.current.click();
    };
    const handleFormSubmit = async (event) => {
        const form = event.currentTarget;
        let validatedTerm = validated;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            validatedTerm = true;
            setValidated(validatedTerm);
        } else {
            if (!content) {
                setShowMsg('Please input content.');
                return;
            }

            validatedTerm = false;
            setValidated(validatedTerm);
        }

        if (!validatedTerm) {
            let fileSubmitClone = {};
            if (fileSubmit && Object.keys(fileSubmit).length) {
                Object.keys(fileSubmit).forEach((key, index) => {
                    let item = fileSubmit[key];
                    if (!item.id) {
                        fileSubmitClone[index] = item;
                    }
                });
            }

            setLoginLoading(true);

            const res = await put(
                '/new/' + id,
                {
                    new_title: title,
                    new_content: content,
                    new_content_plain_text: contentPlainText,
                    new_type: type,
                    files: fileSubmitClone,
                    delete_files: fileDeleteSubmit,
                },
                {},
                { headers: { accept: '*/*', 'Content-Type': 'application/json' } },
            );

            if (res.status == apiStatus.success) {
                getNew(id);
                setModalMsg('Updated success');
                handleShow();
            } else {
                setShowMsg(res.msg);
            }

            setLoginLoading(false);
        }
    };
    const handleChangeFile = (index, file) => {
        let fileSubmitClone = Object.assign({}, fileSubmit);
        let fileDeleteSubmitClone = Object.assign({}, fileDeleteSubmit);
        let fileSubmitItem = fileSubmitClone[index];

        if (file) {
            fileSubmitClone[index] = {
                base64: file.base64,
                name: file.name,
            };
            setFileSubmit(fileSubmitClone);
        } else {
            delete fileSubmitClone[index];
            setFileSubmit(fileSubmitClone);
        }

        if (fileSubmitItem && fileSubmitItem.id) {
            fileDeleteSubmitClone[index] = fileSubmitItem.id;
            setFileDeleteSubmit(fileDeleteSubmitClone);
        }
    };
    const handleGoNew = () => {
        navigate(-1);
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <h4 className={cx('wrapper-title', 'p-2', 'mb-1', 'mt-1')}>
                <FontAwesomeIcon className={cx('icon')} icon={faArrowLeftLong} onClick={handleGoNew} /> Edit New
            </h4>
            <div className={cx('wrapper-content', 'p-2')}>
                <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} required onChange={(e) => setTitle(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">This field cannot be empty.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom03">
                        <Form.Label>Type</Form.Label>
                        <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="">Normal</option>
                            <option value="IMPORTANT">Important</option>
                            <option value="JOCELYN_SKILL">Jocelyn Skill</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Content</Form.Label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                scayt_autoStartup: false,
                                removePlugins: [],
                                toolbar: [
                                    'bold',
                                    'italic',
                                    '|',
                                    'Heading',
                                    'bulletedList',
                                    'numberedList',
                                    'blockQuote',
                                    'link',
                                    '|',
                                    'undo',
                                    'redo',
                                ],
                            }}
                            data={content}
                            onReady={(editor) => {}}
                            onChange={(event) => {}}
                            onBlur={(event, editor) => {
                                let html = editor.getData();
                                setContent(html);
                                setContentPlainText(
                                    convert(html, {
                                        wordwrap: false,
                                    })
                                        .replace(/\n/g, ' ')
                                        .replace(/\s\s+/g, ' '),
                                );
                            }}
                            onFocus={(event, editor) => {}}
                        />
                        <Button ref={submitLoginRef} variant="primary" type="submit" hidden></Button>
                    </Form.Group>
                    {[0, 1, 2, 3, 4, 5].map((item, index) => {
                        return (
                            <Form.Group key={index} className="mb-3" controlId="validationCustom02">
                                <Form.Label>Image {item + 1}</Form.Label>
                                <ImageBase64
                                    file={fileSubmit[index]}
                                    onChange={(file) => {
                                        handleChangeFile(item, file);
                                    }}
                                />
                            </Form.Group>
                        );
                    })}
                    {showMsg && (
                        <Alert className={cx('mt-3')} variant="danger" onClose={() => setShowMsg(false)} dismissible>
                            {showMsg}
                        </Alert>
                    )}
                    <Form.Group className={cx('d-flex', 'flex-row-reverse')}>
                        <Button
                            variant="primary"
                            onClick={!isLoginLoading ? handleSave : null}
                            disabled={isLoginLoading}
                        >
                            {isLoginLoading ? 'Loading…' : 'Save'}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default AdminNewEdit;
