import { commonType } from '../types';

const commonAction = {};

Object.keys(commonType).forEach((key) => {
    const type = commonType[key];

    commonAction[key] = (data) => {
        return {
            type: type,
            payload: data,
        };
    };
});

export default commonAction;
