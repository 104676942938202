import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import { commonReducer } from '../reducers';
import { authReducer } from '../reducers';
import { initSaga } from '../sagas';

const persistConfig = {
    key: 'persist_storage',
    storage,
    whitelist: ['auth'],
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
});

const middleware = (store) => (next) => (action) => {
    next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Enable redux devTools
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, middleware)));
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, middleware));
sagaMiddleware.run(initSaga);

const persistor = persistStore(store);
export { store, persistor };
