const routes = {
    home: '/',
    aboutMe: '/about-me',
    new: '/new',
    cv: '/cv',
    share: '/share',
    showNew: '/new/:id',
    profile: '/profile',
    adminNew: '/admin/new',
    adminNewStore: '/admin/new/create',
    adminNewEdit: '/admin/new/:id/edit',
    test: '/test',
};

export default routes;
