import { commonType } from '../types';
import { commonAction } from '../actions';
import { getAll } from '~/services/checkService';

import { put, call, takeLeading } from 'redux-saga/effects';

// Fork
export function* updateCheckFork() {
    yield takeLeading(commonType.updateCheckSaga, updateCheckSaga);
}

export function* updateCheckApiFork() {
    yield takeLeading(commonType.updateCheckApiSaga, updateCheckApiSaga);
}

// Saga
function* updateCheckSaga(action) {
    yield put(commonAction.updateCheck(action.payload));
}

function* updateCheckApiSaga(action) {
    const res = yield call(getAllCheck);
    if (res) yield put(commonAction.updateCheck(res[0].name));
}

// Services
const getAllCheck = async (data) => {
    const result = await getAll();
    return result;
};
