import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '~/routes';
import DefaultLayout from '~/layouts';
import NoBannerLayout from './layouts/NoBannerLayout';

import ScrollToTop from './components/ScrollToTop';
import CheckAuth from './components/CheckAuth/CheckAuth';
import NotFound404 from './pages/NotFound404';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                    {privateRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <CheckAuth>
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    </CheckAuth>
                                }
                            />
                        );
                    })}
                    <Route
                        path="*"
                        element={
                            <NoBannerLayout>
                                <NotFound404 />
                            </NoBannerLayout>
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
