import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames/bind';
import Image from 'react-bootstrap/Image';

import { Fragment } from 'react';
import { getTimeAgo } from '~/services/dateTimeService';
import images from '~/assets/images';
import styles from './NewItem.module.scss';

const cx = classNames.bind(styles);

function NewItem({
    id = '',
    main = false,
    title = '',
    content = '',
    author = '',
    createdDate = '',
    image = '',
    content_plain_text = '',
}) {
    const navigate = useNavigate();

    const titleLength = 35;
    const contentLength = 300;

    const handleGoAboutMe = (id) => {
        if (id) {
            navigate('/new/' + id);
        }
    };

    return (
        <Row className={cx('wrapper')}>
            <Col md={4} className={cx('text-center')}>
                <Image
                    className={cx(main ? 'img-main' : 'img-sub')}
                    src={!image ? images.noImage : image}
                    fluid
                    rounded
                />
            </Col>
            <Col md={8}>
                <h5 className={cx('text-primary', 'new-title')} onClick={() => handleGoAboutMe(id)}>
                    {title.length <= titleLength && <Fragment>{title}</Fragment>}
                    {title.length > titleLength && <Fragment>{title.substring(0, titleLength) + '...'}</Fragment>}
                </h5>
                <div className={cx('text-secondary')}>
                    {content_plain_text.length <= contentLength && <Fragment>{content_plain_text}</Fragment>}
                    {content_plain_text.length > contentLength && (
                        <Fragment>{content_plain_text.substring(0, contentLength) + '...'}</Fragment>
                    )}
                </div>
                <div className={cx('time-main', 'fw-light')}>
                    Last updated {getTimeAgo(createdDate)} by {author}
                </div>
            </Col>
        </Row>
    );
}

export default NewItem;
