import { Fragment, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';
import classNames from 'classnames/bind';

import { get } from '~/utils/httpRequest';
import { getTimeAgo } from '~/services/dateTimeService';
import images from '~/assets/images';
import styles from './MySkill.module.scss';
import Loading from '../Loading';

const cx = classNames.bind(styles);

function MySkill() {
    const titleLength = 30;
    const contentLength = 150;

    const navigate = useNavigate();

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNews();
    }, []);

    const getNews = async () => {
        let responseNews = await get('/new', { page: 1, limit: 5, type: 'jocelyn_skill', no_count_list: true });
        setNews(responseNews.data);
        setLoading(false);
    };
    const handleGoNew = (id) => {
        navigate('/new/' + id);
    };
    const handleGoJocelynSkillNew = () => {
        navigate('/new?type=jocelyn_skill');
    };

    return (
        <Fragment>
            {loading ? (
                <Loading />
            ) : news.length > 0 ? (
                <div className={cx('wrapper-content', 'wrapper-item')}>
                    <div className={cx('previous')}>
                        <FontAwesomeIcon
                            className={cx('icon')}
                            icon={faCircleChevronLeft}
                            onClick={handleGoJocelynSkillNew}
                        />
                    </div>
                    {news.map((item, index) => {
                        let imageUrlTerm = false;
                        if (item.image_url) {
                            imageUrlTerm = JSON.parse(item.image_url);
                        }
                        return (
                            <div key={index} className={cx('item', 'p-3')} onClick={() => handleGoNew(item.new_id)}>
                                <div className={cx('text-center')}>
                                    <Image
                                        src={imageUrlTerm ? imageUrlTerm[0]['web_path'] : images.noImage}
                                        className={cx('item-img')}
                                    />
                                </div>
                                <div className={cx('text-primary', 'text-center', 'mt-2', 'fs-4')}>
                                    {item.new_title.substring(0, titleLength)}
                                </div>
                                <div className={cx('mt-2', 'fs-6')}>
                                    {item.new_content_plain_text.substring(0, contentLength)}...
                                </div>
                                <div>
                                    <small className={cx('text-secondary')}>
                                        Last updated {getTimeAgo(item.created_date)} by {item.author_short_name}
                                    </small>
                                </div>
                            </div>
                        );
                    })}
                    <div className={cx('next')}>
                        <FontAwesomeIcon
                            className={cx('icon')}
                            icon={faCircleChevronRight}
                            onClick={handleGoJocelynSkillNew}
                        />
                    </div>
                </div>
            ) : (
                <div className={cx('text-center')}>News is updating</div>
            )}
        </Fragment>
    );
}

export default MySkill;
