import { commonType } from '../types';

const initStage = {
    check: '',
};

const commonReducer = (stage = initStage, action) => {
    switch (action.type) {
        case commonType.updateCheck:
            return {
                ...stage,
                check: action.payload,
            };
        default:
            return stage;
    }
};

export default commonReducer;
