const getTimeAgo = (strDate) => {
    let date = new Date(strDate.replace(/-/g, '/'));
    let currentDate = new Date();
    let currentDateUTC = new Date(
        currentDate.getUTCFullYear() +
            '/' +
            (currentDate.getUTCMonth() + 1) +
            '/' +
            currentDate.getUTCDate() +
            ' ' +
            currentDate.getUTCHours() +
            ':' +
            currentDate.getUTCMinutes() +
            ':' +
            currentDate.getUTCSeconds(),
    );
    let seconds = Math.floor((currentDateUTC - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
};

export { getTimeAgo };
